/* Category */

.page-type-category .qty-wrapper {
font-size:1em;
margin:5px 0 10px;
}

.products-line .product-image img {
width:44%;
}

.page-type-category .products-line .addto-links-icons.addto-onimage {
float:left;
margin-top:-32px;
position:relative;
z-index:1;
padding-bottom:4px;
padding-right:4px;
margin-left:65px;
}

.page-type-category .products-line .qty-wrapper {
float:right;
margin-top:-33px;
margin-right:29px;
}

.page-type-category .category-title {
    /*margin-bottom: 1%;*/
}

.page-type-category .std dl,
.page-type-category .std p,
.page-type-category .std address, .std blockquote {
    margin: 0;
}

.page-type-category .horizontal-section .page-content
{
    margin: 0px;
    padding: 0px;
}
.module-category-misc > div {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
}
.module-category-misc > div:first-of-type {
    -webkit-box-ordinal-group: 2;
        -moz-box-ordinal-group: 2;
        box-ordinal-group: 2;
}
.module-category-misc > div:first-of-type + div {
    -webkit-box-ordinal-group: 1;
        -moz-box-ordinal-group: 1;
        box-ordinal-group: 1;
}
.module-category-misc .amount {
    line-height: 36px;
}
ul.category-products-listing > li {
    padding: 5px;
}
ul.category-products-listing > li:nth-of-type(even) {
    background-color: #f2f2f2;
}
.cat-title-column.grid12-8 {
    width: 64.67% !important;
}
.cat-filter-column.grid12-4 {
    width: 31.33% !important;
}
@media only screen and (min-width: 768px) and (max-width: 959px) {
    .products-grid.single-line-name .add-cart-wrapper button {
        width: 80%;
    }
    .products-grid.single-line-name .add-cart-wrapper button span {
        display: block;
        width: 100%;
    }
    .products-grid.single-line-name .add-cart-wrapper button span span {
        width: 100%;
    }
    .products-grid.single-line-name button.button span span {
        padding: 0;
    }
}
